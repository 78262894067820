import React from "react";
import { Route } from "react-router-dom";
import { Content } from "./Content";

import { shallowEqual, useSelector } from "react-redux";

export function ContentRoute(props) {
  const { children, component, roles = [], render } = props;

  const { isAuthorized, user } = useSelector(
    ({ auth }) => ({ user: auth.user, isAuthorized: auth.user != null }),
    shallowEqual
  );
  let exist = false;
  if (isAuthorized) {
    exist = roles.filter((value) => user.roles.includes(value)).length > 0;
  } else {
    exist = true;
  }

  return (
    <Route {...props}>
      {(routeProps) => {
        if (typeof children === "function") {
          return <Content>{children(routeProps)}</Content>;
        }

        if (!routeProps.match) {
          return null;
        }

        if (children) {
          return <Content>{children}</Content>;
        }

        if (component) {
          return (
            <Content>{React.createElement(component, routeProps)}</Content>
          );
        }

        if (render) {
          return <Content>{render(routeProps)}</Content>;
        }

        return null;
      }}
    </Route>
  );
}
