import React from "react";
import ReviewsFilters from "./ReviewsFilters";
import { Card, CardBody } from "../../../../../_metronic/_partials/controls";
import ReviewsTable from "./ReviewsTable";

const ReviewsList = () => {
  return (
    <div>
      {/* <ReviewsFilters /> */}
      <Card>
        <CardBody>
          <ReviewsTable />
        </CardBody>
      </Card>
    </div>
  );
};

export default ReviewsList;
