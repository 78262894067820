import React from "react";
import TableWithKeys from "../../../../../_ae/components/Tables/TableWithKeys";
import { columns } from "./columns";
import { useEffect } from "react";
import { all } from "../../../../../redux/reviews/api";
import { useState } from "react";
import { Loader } from "../../../../../_ae/components/loader";

const ReviewsTable = () => {
  const [entities, setEntities] = useState(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    all().then((response) => {
      setEntities(response.data.entities);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <Loader isLoading={loading} />
      {entities && (
        <TableWithKeys
          title="Commentaires"
          columns={columns}
          entities={entities}
        />
      )}
    </>
  );
};

export default ReviewsTable;
