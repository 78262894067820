import { MODULES } from "../../_ae/helpers/RoutingHelpers";
import { toAbsoluteApiUrl } from "../../_metronic/_helpers";
import axios from "axios";
export const API_URI = toAbsoluteApiUrl(`/${MODULES.REVIEWS}`);

export const all = () => {
  return axios.get(API_URI);
};

export const validate = (entity) =>
  axios.put(`${API_URI}/validate/${entity.id}`, JSON.stringify(entity));
