import { Rating } from "@material-ui/lab";
import React from "react";
import ValidateReview from "./ValidateReview";
import { RatingStatus } from "./RatingStatus";
export const columns = [
  {
    header: "Client",
    data: "_user",
    // render: (d) => {
    //   console.log({ d });
    //   return d.user.name;
    // },
  },
  {
    header: "Commentaire",
    data: "comment",
  },

  {
    header: "Statut",
    data: "valid",
    render: (d) => <Rating value={d.rating} readOnly />,
  },
  {
    header: "Avis",
    data: "rating",
    render: (d) => {
      return !!d.valid ? (
        <RatingStatus status="VALID" />
      ) : (
        <RatingStatus status="INVALID" />
      );
    },
  },
  {
    header: "Actions",
    render: (d) => <ValidateReview review={d} />,
  },
];
