import React from "react";

const TableWithKeys = ({
  className,
  title,
  subtitle,
  entities = [],
  columns = [],
  toolbar,
}) => {
  console.log({ entities });
  return (
    <div className={`card card-custom ${className}`}>
      {/* Head */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            {title}
          </span>
          <span className="text-muted mt-3 font-weight-bold font-size-sm">
            {subtitle}
          </span>
        </h3>
        <div className="card-toolbar">{toolbar}</div>
      </div>
      {/* Body */}
      <div className="card-body pt-3 pb-0">
        <div className="table-responsive">
          <table className="table table-sm table-hover table-head-custom table-head-bg table-borderless table-vertical-center">
            <thead>
              <tr className="pl-0">
                {columns.map((c) => (
                  <th key={c.header}>{c.header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {entities.map((r) => {
                return (
                  <tr key={r.id} className="border-bottom rounded">
                    {columns.map((c, index) => {
                      return (
                        <td key={index}>
                          {" "}
                          {c.render ? c.render(r) : r[c.data]}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TableWithKeys;
