export const MODULES = {
  USERS: "users",
  AREAS: "areas",
  ROUTES: "routes",
  ROLES: "roles",
  LOGS: "logs",

  CATEGORIES: "categories",
  POSTS: "posts",
  SALES: "sales",
  PACKS: "packs",
  PRODUCTS: "products",
  VARIANTS: "variants",
  ATTRIBUTES: "attributes",
  BANNERS: "banners",
  ORDERS: "orders",
  VENDORS: "vendors",
  STOCK_ORDERS: "stock_orders",
  CONTACT_MESSAGES: "contact_messages",
  REVIEWS: "reviews",
  PROFILE: "profile",
  AUTH: "auth",
};
export const VIEWS = {
  MENU: "MENU",
  ACTION: "ACTION",
  DIALOG: "DIALOG",
};
