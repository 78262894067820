import React from "react";
import { validate } from "../../../../../redux/reviews/api";

const ValidateReview = ({ review }) => {
  const validateReview = (review) => {
    validate(review)
      .then((response) => console.log({ response }))
      .catch((error) => console.error({ error }));
  };
  return (
    <button className="btn btn-primary" onClick={() => validateReview(review)}>
      Valider
    </button>
  );
};

export default ValidateReview;
