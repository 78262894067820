import { FormattedMessage } from "react-intl";
import { AELabel } from "../../../../../_ae/components/AELabel";
import React from "react";

const RATING_STATUSES = {
  VALID: {
    key: "VALID",
    variant: "success",
  },
  INVALID: {
    key: "INVALID",
    variant: "danger",
  },
};
export const getRatingStatusKeys = () =>
  Object.values(RATING_STATUSES).map(({ key }) => key);
export const ratingStatusKeyExist = (key) =>
  getRatingStatusKeys().includes(key);

export const RatingStatus = ({
  status = RATING_STATUSES.INVALID.key,
  ...props
}) => {
  if (!ratingStatusKeyExist(status)) {
    return null;
  }
  const { key, variant } = RATING_STATUSES[status];

  return (
    <AELabel variant={variant} {...props}>
      <FormattedMessage id={key} />
    </AELabel>
  );
};
